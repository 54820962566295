import { Select } from "antd";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { getSingleSelectSearch } from "../../redux/actions/opd/patientreport";

const { Option } = Select;
const SingleSelect = (props) => {
  const dispatch = useDispatch()

  // function onSearch(val) {
  //   dispatch(getSingleSelectSearch(val))
  //   console.log("search:", val);
  // }
  let options = props?.data?.map((data, index) => {
    if (data && Object.keys(data).includes('disabled')) {
      return (
        <Option disabled={data?.disabled} key={index} value={data?.name}>
          {data?.name}
        </Option>
      )} else {
      return (
        <Option key={index} value={data?.id || data || data?.name}>
          {data?.name || data}
        </Option>
      )}

  });


  return (
    <Select
      showSearch
      placeholder={props.placeholder}
      // style={{ width: props.width, height: props.height, padding: props.padding, paddingLeft: props.paddingLeft }}
      optionFilterProp="children"
      value={props.value}
      onChange={props.onChange}
      onSearch={props.onSearch}
      listHeight={props.listHeight}
      className={props.className}
      dropdownClassName={props.dropdownClassName}
      disabled={props.disabled}
      filterOption={(input, option) =>
        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
    >
      {options}
    </Select>
  );
};
export default SingleSelect;
