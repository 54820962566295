//login
export const LOGIN_DOCTOR_LOADING = "LOGIN_DOCTOR_LOADING";
export const LOGIN_DOCTOR_SUCCESS = "LOGIN_DOCTOR_SUCCESS";
export const LOGIN_DOCTOR_FAIL = "LOGIN_DOCTOR_FAIL";
export const STORE_DOCTOR_ID = "STORE_DOCTOR_ID";

// Admin-Login
export const ADMIN_LOGIN_LOADING = "ADMIN_LOGIN_LOADING";
export const ADMIN_LOGIN_SUCCESS = "ADMIN_LOGIN_SUCCESS";
export const ADMIN_LOGIN_FAIL = "ADMIN_LOGIN_FAIL";

// Appointments
export const GET_DATE = "GET_DATE";
export const GET_SCHEDULED_APPOINTMENTS = "GET_SCHEDULED_APPOINTMENTS";
export const GET_APPOINTMENTS_LOADING = "GET_APPOINTMENTS_LOADING";
export const GET_APPOINTMENTS_SUCCESS = "GET_APPOINTMENTS_SUCCESS";
export const GET_APPOINTMENTS_FAIL = "GET_APPOINTMENTS_FAIL";

//Invoice sms
export const INVOICE_SMS_LOADING = "INVOICE_SMS_LOADING";
export const INVOICE_SMS_SUCCESS = "INVOICE_SMS_SUCCESS";
export const INVOICE_SMS_FAIL = "INVOICE_SMS_FAIL";

//Appointments Patient Search
export const CLEAR_SEARCH_PATIENT_SUCCESS = "CLEAR_SEARCH_PATIENT_SUCCESS";
export const SEARCH_PATIENT_LOADING = "SEARCH_PATIENT_LOADING";
export const SEARCH_PATIENT_SUCCESS = "SEARCH_PATIENT_SUCCESS";
export const SEARCH_PATIENT_FAIL = "SEARCH_PATIENT_FAIL";
export const CREATE_TOGGLE = "CREATE_TOGGLE";
export const RESET_SEARCH_PATIENT = "RESET_SEARCH_PATIENT";

//Appointments patient advance search
export const SEARCH_PATIENT_ADV_LOADING = "SEARCH_PATIENT_ADV_LOADING";
export const SEARCH_PATIENT_ADV_SUCCESS = "SEARCH_PATIENT_ADV_SUCCESS";
export const SEARCH_PATIENT_ADV_FAIL = "SEARCH_PATIENT_ADV_FAIL";
export const RESET_SEARCH_PATIENT_ADV = "RESET_SEARCH_PATIENT_ADV";

// Create-Patient
export const UPDATE_EVENTS = "UPDATE_EVENTS";
export const CREATE_PATIENT_LOADING = "CREATE_PATIENT_LOADING";
export const CREATE_PATIENT_SUCCESS = "CREATE_PATIENT_SUCCESS";
export const CREATE_PATIENT_FAIL = "CREATE_PATIENT_FAIL";
export const GET_CREATE_PATIENT_STATUS = "GET_CREATE_PATIENT_STATUS";
export const GET_URL = "GET_URL";
export const CREATE_PATIENT_TOGGLE = "CREATE_PATIENT_TOGGLE";
export const NEW_PATIENT_TOGGLE = "NEW_PATIENT_TOGGLE";

//File-no
export const FILE_NO_LOADING = "FILE_NO_LOADING";
export const FILE_NO_SUCCESS = "FILE_NO_SUCCESS";
export const FILE_NO_FAIL = "FILE_NO_FAIL";

//Add-Appointment

export const ADD_APPOINTMENT_LOADING = "ADD_APPOINTMENT_LOADING";
export const ADD_APPOINTMENT_SUCCESS = "ADD_APPOINTMENT_SUCCESS";
export const ADD_APPOINTMENT_FAIL = "ADD_APPOINTMENT_FAIL";

export const SEARCH_CREATE_LOADING = "SEARCH_CREATE_LOADING";
export const SEARCH_CREATE_SUCCESS = "SEARCH_CREATE_SUCCESS";
export const SEARCH_CREATE_FAIL = "SEARCH_CREATE_FAIL";

//Get-Appointment
export const SINGLE_PATIENT_TOGGLE = "SINGLE_PATIENT_TOGGLE";
export const GET_PATIENT_APPOINTMENT_LOADING =
  "GET_PATIENT_APPOINTMENT_LOADING";
export const GET_PATIENT_APPOINTMENT_SUCCESS =
  "GET_PATIENT_APPOINTMENT_SUCCESS";
export const GET_PATIENT_APPOINTMENT_FAIL = "GET_PATIENT_APPOINTMENT_FAIL";
export const GET_PATIENT_APPOINTMENT_MODIFIED =
  "GET_PATIENT_APPOINTMENT_MODIFIED";
export const COPY_BUTTON = "COPY_BUTTON";
export const NARRATIVES_FINAL_DATA = "NARRATIVES_FINAL_DATA";

//Save-Patient-Address

export const SAVE_PATIENT_LOADING = "SAVE_PATIENT_LOADING";
export const SAVE_PATIENT_SUCCESS = "SAVE_PATIENT_SUCCESS";
export const SAVE_PATIENT_FAIL = "SAVE_PATIENT_FAIL";

//Delete-Appointment

export const DELETE_APPOINTMENT_LOADING = "DELETE_APPOINTMENT_LOADING";
export const DELETE_APPOINTMENT_SUCCESS = "DELETE_APPOINTMENT_SUCCESS";
export const DELETE_APPOINTMENT_FAIL = "DELETE_APPOINTMENT_FAIL";

//Update-appointment

export const UPDATE_APPOINTMENT_LOADING = "UPDATE_APPOINTMENT_LOADING";
export const UPDATE_APPOINTMENT_SUCCESS = "UPDATE_APPOINTMENT_SUCCESS";
export const UPDATE_APPOINTMENT_FAIL = "UPDATE_APPOINTMENT_FAIL";

//Acess-token

export const ACCESS_TOKEN_LOADING = "ACCESS_TOKEN_LOADING";
export const ACCESS_TOKEN_SUCCESS = "ACCESS_TOKEN_SUCCESS";
export const ACCESS_TOKEN_FAIL = "ACCESS_TOKEN_FAIL";
export const ACCESS_TOKEN_NO = "ACCESS_TOKEN_NO";

//Set-partner

export const SET_PARTNER_LOADING = "SET_PARTNER_LOADING";
export const SET_PARTNER_SUCCESS = "SET_PARTNER_SUCCESS";
export const SET_PARTNER_FAIL = "SET_PARTNER_FAIL";

//Update-appointment-view

export const UPDATE_APPOINTMENT_VIEW_LOADING =
  "UPDATE_APPOINTMENT_VIEW_LOADING";
export const UPDATE_APPOINTMENT_VIEW_SUCCESS =
  "UPDATE_APPOINTMENT_VIEW_SUCCESS";
export const UPDATE_APPOINTMENT_VIEW_FAIL = "UPDATE_APPOINTMENT_VIEW_FAIL";

//print-report-appointment-view

export const PRINT_REPOPRT_APPOINTMENT_LOADING =
  "PRINT_REPOPRT_APPOINTMENT_LOADING";
export const PRINT_REPOPRT_APPOINTMENT_SUCCESS =
  "PRINT_REPOPRT_APPOINTMENT_SUCCESS";
export const PRINT_REPOPRT_APPOINTMENT_FAIL = "PRINT_REPOPRT_APPOINTMENT_FAIL";

//print-patient-profile

export const PRINT_PATIENT_PROFILE_LOADING = "PRINT_PATIENT_PROFILE_LOADING";
export const PRINT_PATIENT_PROFILE_SUCCESS = "PRINT_PATIENT_PROFILE_SUCCESS";
export const PRINT_PATIENT_PROFILE_FAIL = "PRINT_PATIENT_PROFILE_FAIL";
export const PRINT_PATIENT_PROFILE_TOGGLE = "PRINT_PATIENT_PROFILE_TOGGLE";

//edit-invoice

export const EDIT_INVOICE_LOADING = "EDIT_INVOICE_LOADING";
export const EDIT_INVOICE_SUCCESS = "EDIT_INVOICE_SUCCESS";
export const EDIT_INVOICE_FAIL = "EDIT_INVOICE_FAIL";
export const ADD_BUTTON = "ADD_BUTTON";

//print-invoice-appointment-view

export const PRINT_INVOICE_LOADING = "PRINT_INVOICE_LOADING";
export const PRINT_INVOICE_SUCCESS = "PRINT_INVOICE_SUCCESS";
export const PRINT_INVOICE_FAIL = "PRINT_INVOICE_FAIL";
export const SPEECH = "SPEECH";
export const SPEECH_FOR_DIAGNOSTIC = "SPEECH_FOR_DIAGNOSTIC";
export const SPEECH_FOR_VITALS = "SPEECH_FOR_VITALS";

//Diagnostics
export const GET_PATIENT_CLICK = "GET_PATIENT_CLICK";
export const GET_ALL_DIAGNOSTIC_LOADING = "GET_ALL_DIAGNOSTIC_LOADING";
export const GET_ALL_DIAGNOSTIC_SUCCESS = "GET_ALL_DIAGNOSTIC_SUCCESS";
export const GET_ALL_DIAGNOSTIC_FAIL = "GET_ALL_DIAGNOSTIC_FAIL";

export const CLOSE_DIAGNOSTICS_LOADING = "CLOSE_DIAGNOSTICS_LOADING";
export const CLOSE_DIAGNOSTICS_SUCCESS = "CLOSE_DIAGNOSTICS_SUCCESS";
export const CLOSE_DIAGNOSTICS_FAIL = "CLOSE_DIAGNOSTICS_FAIL";

export const GET_ALL_DIAGNOSTIC_LIST_LOADING =
  "GET_ALL_DIAGNOSTIC_LIST_LOADING";
export const GET_ALL_DIAGNOSTIC_LIST_SUCCESS =
  "GET_ALL_DIAGNOSTIC_LIST_SUCCESS";
export const GET_ALL_DIAGNOSTIC_LIST_FAIL = "GET_ALL_DIAGNOSTIC_LIST_FAIL";

export const GET_DIAGNOSTIC_LOADING = "GET_DIAGNOSTIC_LOADING";
export const GET_DIAGNOSTIC_SUCCESS = "GET_DIAGNOSTIC_SUCCESS";
export const GET_DIAGNOSTIC_FAIL = "GET_DIAGNOSTIC_FAIL";

//GET PATIENT
export const GET_PROFILE_TOGGLE = "GET_PROFILE_TOGGLE";
export const GET_PATIENT_LOADING = "GET_PATIENT_LOADING";
export const GET_PATIENT_SUCCESS = "GET_PATIENT_SUCCESS";
export const GET_PATIENT_FAIL = "GET_PATIENT_FAIL";
export const GET_PATIENT_DETAILS = "GET_PATIENT_DETAILS";
export const GENDER_NAME = "GENDER_NAME";
export const RESET_GET_PATIENT = "RESET_GET_PATIENT";

//INVOICE SUMMARY
export const INVOICE_SUMMARY_LOADING = "INVOICE_SUMMARY_LOADING";
export const INVOICE_SUMMARY_SUCCESS = "INVOICE_SUMMARY_SUCCESS";
export const INVOICE_SUMMARY_FAIL = "INVOICE_SUMMARY_FAIL";

//SAVE DIAGNOSTIC
export const SAVE_DIAGNOSTIC_LOADING = "SAVE_DIAGNOSTIC_LOADING";
export const SAVE_DIAGNOSTIC_SUCCESS = "SAVE_DIAGNOSTIC_SUCCESS";
export const SAVE_DIAGNOSTIC_FAIL = "SAVE_DIAGNOSTIC_FAIL";
export const RESET_ADDED_DATA = "RESET_ADDED_DATA";
export const RESET_DIAGNOSTIC_SUCCESS_DATA = "RESET_DIAGNOSTIC_SUCCESS_DATA";

//GET DIAGNOSTIC PROFILE
export const GET_DIAGNOSTIC_PROFILE_LOADING = "GET_DIAGNOSTIC_PROFILE_LOADING";
export const GET_DIAGNOSTIC_PROFILE_SUCCESS = "GET_DIAGNOSTIC_PROFILE_SUCCESS";
export const GET_DIAGNOSTIC_PROFILE_FAIL = "GET_DIAGNOSTIC_PROFILE_FAIL";

// ADD SERVICE DETAILS IN DIAGNOSTIC

export const ADD_DIAGNOSTIC_SERVICE = "ADD_DIAGNOSTIC_SERVICE";
export const UPDATE_DIAGNOSTIC_SERVICE = "UPDATE_DIAGNOSTIC_SERVICE";
export const CREATE_DIAGNOSTIC_SERVICE = "CREATE_DIAGNOSTIC_SERVICE";
export const DELETE_API_IMAGE = "DELETE_API_IMAGE";
export const SELECT_BUTTON_TOGGLE = "SELECT_BUTTON_TOGGLE";

//PRINT TEST REPORTS
export const PRINT_TEST_REPORTS_LOADING = "PRINT_TEST_REPORTS_LOADING";
export const PRINT_TEST_REPORTS_SUCCESS = "PRINT_TEST_REPORTS_SUCCESS";
export const PRINT_TEST_REPORTS_FAIL = "PRINT_TEST_REPORTS_FAIL";

//PRINT DIAGNOSTICS INVOICE
export const PRINT_DIAGNOSTICS_INVOICE_LOADING =
  "PRINT_DIAGNOSTICS_INVOICE_LOADING";
export const PRINT_DIAGNOSTICS_INVOICE_SUCCESS =
  "PRINT_DIAGNOSTICS_INVOICE_SUCCESS";
export const PRINT_DIAGNOSTICS_INVOICE_FAIL = "PRINT_DIAGNOSTICS_INVOICE_FAIL";

// SAVE DIAGNOSTIC REPORT
export const SAVE_DIAGNOSTIC_REPORT_LOADING = "SAVE_DIAGNOSTIC_REPORT_LOADING";
export const SAVE_DIAGNOSTIC_REPORT_SUCCESS = "SAVE_DIAGNOSTIC_REPORT_SUCCESS";
export const SAVE_DIAGNOSTIC_REPORT_FAIL = "SAVE_DIAGNOSTIC_REPORT_FAIL";

export const SAVE_REPORT = "SAVE_REPORT";
export const UPDATE_REPORT = "UPDATE_REPORT";
export const IMAGE_URL = "IMAGE_URL";
export const REPORT_NAME = "REPORT_NAME";
export const FIELD = "FIELD";
export const SINGLE_REPORT_DATA = "SINGLE_REPORT_DATA";
export const INDEX_VALUE = "INDEX_VALUE";
export const TOGGLE_BUTTON = "TOGGLE_BUTTON";
export const RESET_GET_DIAGNOSTICS = "RESET_GET_DIAGNOSTICS";
export const SELECT_HANDLE = "SELECT_HANDLE";
export const START_DATE = "START_DATE";
export const END_DATE = "END_DATE";

// REOPEN DIAGNOSTIC REPORT
export const REOPEN_DIAGNOSTIC_REPORT_LOADING =
  "REOPEN_DIAGNOSTIC_REPORT_LOADING";
export const REOPEN_DIAGNOSTIC_REPORT_SUCCESS =
  "REOPEN_DIAGNOSTIC_REPORT_SUCCESS";
export const REOPEN_DIAGNOSTIC_REPORT_FAIL = "REOPEN_DIAGNOSTIC_REPORT_FAIL";

// Add bank diagnotics
export const ADD_BANK_DIAGNOSTICS_LOADING = "ADD_BANK_DIAGNOSTICS_LOADING";
export const ADD_BANK_DIAGNOSTICS_SUCCESS = "ADD_BANK_DIAGNOSTICS_SUCCESS";
export const ADD_BANK_DIAGNOSTICS_FAIL = "ADD_BANK_DIAGNOSTICS_FAIL";

export const AUTO_OPEN_REC_VISIT = "AUTO_OPEN_REC_VISIT";

// Send SMS Diagnostics

export const SEND_SMS_DIAGNOSTICS_LOADING = "SEND_SMS_DIAGNOSTICS_LOADING";
export const SEND_SMS_DIAGNOSTICS_SUCCESS = "SEND_SMS_DIAGNOSTICS_SUCCESS";
export const SEND_SMS_DIAGNOSTICS_FAIL = "SEND_SMS_DIAGNOSTICS_FAIL";

//profile
export const GET_PROFILE_LOADING = "GET_PROFILE_LOADING";
export const GET_PROFILE_SUCCESS = "GET_PROFILE_SUCCESS";
export const GET_PROFILE_FAIL = "GET_PROFILE_FAIL";
export const UPDATE_PROFILE_LOADING = "UPDATE_PROFILE_LOADING";
export const UPDATE_PROFILE_SUCCESS = "UPDATE_PROFILE_SUCCESS";
export const UPDATE_PROFILE_FAIL = "UPDATE_PROFILE_FAIL";
export const UPDATE_PROFILE_DATA = "UPDATE_PROFILE_DATA";
export const SELECTED_INDEX = "SELECTED_INDEX";
export const EDIT_VISIBLE = "EDIT_VISIBLE";
export const CLINIC_LOCATIONS = "CLINIC_LOCATIONS";
export const CLINIC_LOCATION_ID = "CLINIC_LOCATION_ID";
export const DOCTOR_PROFILE_THEME_APPLY = "DOCTOR_PROFILE_THEME_APPLY";
export const DOCTOR_PROFILE_UPLOAD_IMAGE_SUCCESS =
  "DOCTOR_PROFILE_UPLOAD_IMAGE_SUCCESS";

//opd record
export const GET_PATIENT_LIST_LOADING = "GET_PATIENT_LIST_LOADING";
export const GET_PATIENT_LIST_LOADING_SUCCESS =
  "GET_PATIENT_LIST_LOADING_SUCCESS";
export const GET_PATIENT_LIST_LOADING_FAIL = "GET_PATIENT_LIST_LOADING_FAIL";
export const GET_ALL_PATIENTS_LIST = "GET_ALL_PATIENTS_LIST";

// Analytics invoice summary

export const GET_DOCTOR_INVOICE_SUMMARY_LOADING =
  "GET_DOCTOR_INVOICE_SUMMARY_LOADING";
export const GET_DOCTOR_INVOICE_SUMMARY_SUCCESS =
  "GET_DOCTOR_INVOICE_SUMMARY_SUCCESS";
export const GET_DOCTOR_INVOICE_SUMMARY_FAIL =
  "GET_DOCTOR_INVOICE_SUMMARY_FAIL";

export const EXPORT_TOGGLE = "EXPORT_TOGGLE";

export const BAR_GRAPH_TOGGLE = "BAR_GRAPH_TOGGLE";

export const GET_MONTHLY_SPLIT = "GET_MONTHLY_SPLIT";
// Analytics patient stats
export const PATIENT_STATS_LOADING = "PATIENT_STATS_LOADING";
export const PATIENT_STATS_SUCCESS = "PATIENT_STATS_SUCCESS";
export const PATIENT_STATS_FAIL = "PATIENT_STATS_FAIL";

export const HIDE_DATAON_TABCLICK = "HIDE_DATAON_TABCLICK";

export const CLEAR_DATAON_TABCLICK = "CLEAR_DATAON_TABCLICK";

export const CLEAR_DATES_TABCLICK = "CLEAR_DATES_TABCLICK";

export const CLEAR_DROPDOWN = "CLEAR_DROPDOWN";

// Analytics prescription analysis
export const PRESCRIPTION_ANALYSIS_LOADING = "PRESCRIPTION_ANALYSIS_LOADING";
export const PRESCRIPTION_ANALYSIS_SUCCESS = "PRESCRIPTION_ANALYSIS_SUCCESS";
export const PRESCRIPTION_ANALYSIS_FAIL = "PRESCRIPTION_ANALYSIS_FAIL";

// Analytics invoice details
export const INVOICE_DETAILS_LOADING = "INVOICE_DETAILS_LOADING";
export const INVOICE_DETAILS_SUCCESS = "INVOICE_DETAILS_SUCCESS";
export const INVOICE_DETAILS_FAIL = "INVOICE_DETAILS_FAIL";
export const UPDATE_INVOICE_DETAILS = "UPDATE_INVOICE_DETAILS";

//Analytics Daily Collection
export const DAILY_COLLECTION_LOADING = "DAILY_COLLECTION_LOADING";
export const DAILY_COLLECTION_SUCCESS = "DAILY_COLLECTION_SUCCESS";
export const DAILY_COLLECTION_FAIL = "DAILY_COLLECTION_FAIL";

//Admin//UserSignup
export const USER_SIGN_UP_LOADING = "USER_SIGN_UP_LOADING";
export const USER_SIGN_UP_SUCCESS = "USER_SIGN_UP_SUCCESS";
export const USER_SIGN_UP_FAIL = "USER_SIGN_UP_FAIL";

//Admin//DoctorSignUp//Edit or Create Doctor
export const CREATE_DOCTOR_LOADING = "CREATE_DOCTOR_LOADING";
export const CREATE_DOCTOR_SUCCESS = "CREATE_DOCTOR_SUCCESS";
export const CREATE_DOCTOR_FAIL = "CREATE_DOCTOR_FAIL";
export const RESET_ADMIN_DATA = "RESET_ADMIN_DATA";

//Admin//DoctorSignUp//Doctor List
export const DOCTOR_LIST_LOADING = "DOCTOR_LIST_LOADING";
export const DOCTOR_LIST_SUCCESS = "DOCTOR_LIST_SUCCESS";
export const DOCTOR_LIST_FAIL = "DOCTOR_LIST_FAIL";

//Admin//DoctorSignUp/DocInfo
export const DOCTOR_INFO_LOADING = "DOCTOR_INFO_LOADING";
export const DOCTOR_INFO_SUCCESS = "DOCTOR_INFO_SUCCESS";
export const DOCTOR_INFO_FAIL = "DOCTOR_INFO_FAIL";
export const RESET_DOCTOR_INFO = "RESET_DOCTOR_INFO";

//Admin//DoctorSignUp//Doctor List/ onclick list
export const DOC_LIST = "DOC_LIST";

//Admin//DoctorSignUp//EditCancel
export const DOC_EDIT = "DOC_EDIT";

//Admin//DoctorSignUp//InputFields

export const DOC_INPUT = "DOC_INPUT";
export const DOC_CLEAR = "DOC_CLEAR";
export const DOC_REND = "DOC_REND";

//Admin//DoctorSignUp//Initiliaze doc

export const DOCTOR_INITIALIZE_LOADING = "DOCTOR_INITIALIZE_LOADING";
export const DOCTOR_INITIALIZE_SUCCESS = "DOCTOR_INITIALIZE_SUCCESS";
export const DOCTOR_INITIALIZE_FAIL = "DOCTOR_INITIALIZE_FAIL";

//Admin//DoctorSignUp//Add Assistant to Doctor
export const ASSISTANT_TO_DOC_LOADING = "ASSISTANT_TO_DOC_LOADING";
export const ASSISTANT_TO_DOC_SUCCESS = "ASSISTANT_TO_DOC_SUCCESS";
export const ASSISTANT_TO_DOC_FAIL = "ASSISTANT_TO_DOC_FAIL";
export const ASSISTANT_MAPPED_LOADING = "ASSISTANT_MAPPED_LOADING";
export const ASSISTANT_MAPPED_SUCCESS = "ASSISTANT_MAPPED_SUCCESS";
export const ASSISTANT_MAPPED_FAIL = "ASSISTANT_MAPPED_FAIL";

//Admin//Alternate Number
export const ALTERNATE_NUM_LOADING = "ALTERNATE_NUM_LOADING ";
export const ALTERNATE_NUM_SUCCESS = "ALTERNATE_NUM_SUCCESS";
export const ALTERNATE_NUM_FAIL = "ALTERNATE_NUM_FAIL";

//Admin//Edit Doctor

export const EDIT_DOCTOR_LOADING = "EDIT_DOCTOR_LOADING";
export const EDIT_DOCTOR_SUCCESS = "EDIT_DOCTOR_SUCCESS";
export const EDIT_DOCTOR_FAIL = "EDIT_DOCTOR_FAIL";

//Admin//SubDomain/Register

export const SUB_DOMAIN_REGISTER_LOADING = "SUB_DOMAIN_REGISTER_LOADING";
export const SUB_DOMAIN_REGISTER_SUCCESS = "SUB_DOMAIN_REGISTER_SUCCESS";
export const SUB_DOMAIN_REGISTER_FAIL = "SUB_DOMAIN_REGISTER_FAIL";

export const MY_DOMAIN_LOADING = "MY_DOMAIN_LOADING";
export const MY_DOMAIN_SUCCESS = "MY_DOMAIN_SUCCESS";
export const MY_DOMAIN_FAIL = "MY_DOMAIN_FAIL";

//Admin//SubDomain/Update

export const SUB_DOMAIN_UPDATE_LOADING = "SUB_DOMAIN_UPDATE_LOADING";
export const SUB_DOMAIN_UPDATE_SUCCESS = "SUB_DOMAIN_UPDATE_SUCCESS";
export const SUB_DOMAIN_UPDATE_FAIL = "SUB_DOMAIN_UPDATE_FAIL";

//Admin//SubDomain/Update
export const SUB_DOMAIN_USER_ADD_LOADING = "SUB_DOMAIN_USER_ADD_LOADING";
export const SUB_DOMAIN_USER_ADD_SUCCESS = "SUB_DOMAIN_USER_ADD_SUCCESS";
export const SUB_DOMAIN_USER_ADD_FAIL = "SUB_DOMAIN_USER_ADD_FAIL";

//Admin//SubDomain//ProfilePic
export const SUB_DOMAIN_PIC_LOADING = "SUB_DOMAIN_PIC_LOADING";
export const SUB_DOMAIN_PIC_SUCCESS = "SUB_DOMAIN_PIC_SUCCESS";
export const SUB_DOMAIN_PIC_FAIL = "SUB_DOMAIN_PIC_FAIL";

//Admin//SubDomain//style
export const SUB_DOMAIN_STYLE_LOADING = "SUB_DOMAIN_STYLE_LOADING";
export const SUB_DOMAIN_STYLE_SUCCESS = "SUB_DOMAIN_STYLE_SUCCESS";
export const SUB_DOMAIN_STYLE_FAIL = "SUB_DOMAIN_STYLE_FAIL";

//medicine master

export const MEDICINE_MASTER_LOADING = "MEDICINE_MASTER_LOADING";
export const MEDICINE_MASTER_SUCCESS = "MEDICINE_MASTER_SUCCESS";
export const MEDICINE_MASTER_FAIL = "MEDICINE_MASTER_FAIL";

//medicine master activate

export const MEDICINE_ACTIVATE_LOADING = "MEDICINE_ACTIVATE_LOADING";
export const MEDICINE_ACTIVATE_SUCCESS = "MEDICINE_ACTIVATE_SUCCESS";
export const MEDICINE_ACTIVATE_FAIL = "MEDICINE_ACTIVATE_FAIL";

//medicine master de-activate

export const MEDICINE_DEACTIVATE_LOADING = "MEDICINE_DEACTIVATE_LOADING";
export const MEDICINE_DEACTIVATE_SUCCESS = "MEDICINE_DEACTIVATE_SUCCESS";
export const MEDICINE_DEACTIVATE_FAIL = "MEDICINE_DEACTIVATE_FAIL";

//medicine master download

export const MEDICINE_ADD_LOADING = "MEDICINE_ADD_LOADING";
export const MEDICINE_ADD_SUCCESS = "MEDICINE_ADD_SUCCESS";
export const MEDICINE_ADD_FAIL = "MEDICINE_ADD_FAIL";

//Doctor Statistics

export const DOCTOR_STATISTICS_LOADING = "DOCTOR_STATISTICS_LOADING";
export const DOCTOR_STATISTICS_SUCCESS = "DOCTOR_STATISTICS_SUCCESS";
export const DOCTOR_STATISTICS_FAIL = "DOCTOR_STATISTICS_FAIL";
export const CRON_JOB_STATUS_SUCCESS = "CRON_JOB_STATUS_SUCCESS"
export const CRON_JOB_STATUS_FAIL = "CRON_JOB_STATUS_FAIL"

//Patient add to DND

export const PATIENT_DND_LOADING = "PATIENT_DND_LOADING";
export const PATIENT_DND_SUCCESS = "PATIENT_DND_SUCCESS";
export const PATIENT_DND_FAIL = "PATIENT_DND_FAIL";

//Patient add to DND

export const PATIENT_SUBMIT_LOADING = "PATIENT_SUBMIT_LOADING";
export const PATIENT_SUBMIT_SUCCESS = "PATIENT_SUBMIT_SUCCESS";
export const PATIENT_SUBMIT_FAIL = "PATIENT_SUBMIT_FAIL";

// Opd Patient Save and Checkout
export const SEND_PATIENT_TO_DOCTOR_LOADING = "SEND_PATIENT_TO_DOCTOR_LOADING";
export const SEND_PATIENT_TO_DOCTOR_SUCCESS = "SEND_PATIENT_TO_DOCTOR_SUCCESS";
export const SEND_PATIENT_TO_DOCTOR_ERROR = "SEND_PATIENT_TO_DOCTOR_ERROR";

export const CHECKOUT_PATIENT_LOADING = "CHECKOUT_PATIENT_LOADING";
export const CHECKOUT_PATIENT_SUCCESS = "CHECKOUT_PATIENT_SUCCESS";
export const CHECKOUT_PATIENT_ERROR = "CHECKOUT_PATIENT_ERROR";
export const DOCTOR_PATIENT_CHECKOUT_TOGGLE = "DOCTOR_PATIENT_CHECKOUT_TOGGLE";

//opd patient audio
export const GET_PATIENT_AUDIO = "GET_PATIENT_AUDIO";
export const GET_PATIENT_AUDIO_SUCCESS = "GET_PATIENT_AUDIO_SUCCESS";
export const GET_PATIENT_AUDIO_FAIL = "GET_PATIENT_AUDIO_FAIL";

//opd patient history

export const UPLOAD_VISIT_IMAGE_VAULT_LOADING =
  "UPLOAD_VISIT_IMAGE_VAULT_LOADING";
export const UPLOAD_VISIT_IMAGE_VAULT_SUCCESS =
  "UPLOAD_VISIT_IMAGE_VAULT_SUCCESS";
export const UPLOAD_VISIT_IMAGE_VAULT_FAIL = "UPLOAD_VISIT_IMAGE_VAULT_FAIL";

export const VIEW_VISIT_VAULT_IMAGES_LOADING =
  "VIEW_VISIT_VAULT_IMAGES_LOADING";
export const VIEW_VISIT_VAULT_IMAGES_SUCCESS =
  "VIEW_VISIT_VAULT_IMAGES_SUCCESS";
export const VIEW_VISIT_VAULT_IMAGES_FAIL = "VIEW_VISIT_VAULT_IMAGES_FAIL";

export const VAULT_IMAGES_DELETE_SUCCESS = "VAULT_IMAGES_SUCCESS";

export const GET_SINGLE_APPOINTMENT = "GET_SINGLE_APPOINTMENT";

export const HISTORY_IMAGE_SUCCESS = "HISTORY_IMAGE_SUCCESS";

// opd patient vaccine

export const GET_PATIENT_VACCINE_LOADING = "GET_PATIENT_VACCINE_LOADING";
export const GET_PATIENT_VACCINE_SUCCESS = "GET_PATIENT_VACCINE_SUCCESS";
export const GET_PATIENT_VACCINE_FAIL = "GET_PATIENT_VACCINE_FAIL";
export const DELETE_OPD_VACCINE = "DELETE_OPD_VACCINE";

//opd patient report
export const ALL_REPORTS_TOGGLE = "ALL_REPORTS_TOGGLE";
export const GET_PATIENT_REPORT_LOADING = "GET_PATIENT_REPORT_LOADING";
export const GET_PATIENT_REPORT_SUCCESS = "GET_PATIENT_REPORT_SUCCESS";
export const GET_PATIENT_REPORT_FAIL = "GET_PATIENT_REPORT_FAIL";

export const GET_PATIENT_OPD_REPORT_SUCCESS = "GET_PATIENT_OPD_REPORT_SUCCESS";

export const STORE_ALLERGENS_FROM_FIREBASE = "STORE_ALLERGENS_FROM_FIREBASE";
export const STORE_VITALS_FROM_FIREBASE = "STORE_VITALS_FROM_FIREBASE";
export const STORE_CLINICALFINDING_FROM_FIREBASE =
  "STORE_CLINICALFINDING_FROM_FIREBASE";
export const STORE_RADIOLOGICALFINDING_FROM_FIREBASE =
  "STORE_RADIOLOGICALFINDING_FROM_FIREBASE";
export const STORE_PROCEDURE_FROM_FIREBASE = "STORE_PROCEDURE_FROM_FIREBASE";
export const STORE_LABTESTS_FROM_FIREBASE = "STORE_LABTESTS_FROM_FIREBASE";
export const STORE_DIAGNOSIS_FROM_FIREBASE = "STORE_DIAGNOSIS_FROM_FIREBASE";

export const GET_SELECTED_OPD_PATIENT = "GET_SELECTED_OPD_PATIENT";
export const GET_PATIENT_OPD_LOADING = "GET_PATIENT_OPD_LOADING";
export const GET_PATIENT_OPD_SUCCESS = "GET_PATIENT_OPD_SUCCESS";
export const GET_PATIENT_OPD_FAIL = "GET_PATIENT_OPD_FAIL";
export const PATIENT_PROFILE_TOGGLE = "PATIENT_PROFILE_TOGGLE";

export const RESET_MEDICINE_DATA = "RESET_MEDICINE_DATA";

export const SINGLE_SELECT_SEARCH_VALUE = "SINGLE_SELECT_SEARCH_VALUE";

export const SEARCH_MEDICINE_LOADING = "SESEARCH_MEDICINE_LOADING";
export const SEARCH_MEDICINE_SUCCESS = "SESEARCH_MEDICINE_SUCCESS";
export const SEARCH_MEDICINE_FAIL = "SESEARCH_MEDICINE_FAIL";

export const SELECTED_MEDICINE_REMARKS_SUCCESS =
  "SELECTED_MEDICINE_REMARKS_SUCCESS";

export const GET_SEARCH_TERM_SUCCESS = "GET_SEARCH_TERM_SUCCESS";

export const SAVE_PATIENT_NOTE_LOADING = "SAVE_PATIENT_NOTE_LOADING";
export const SAVE_PATIENT_NOTE_SUCCESS = "SAVE_PATIENT_NOTE_SUCCESS";
export const SAVE_PATIENT_NOTE_FAIL = "SAVE_PATIENT_NOTE_FAIL";

export const STORE_APPOINTMENTS = "STORE_APPOINTMENTS";

export const UPDATE_FORM_SPEECH_DATA = "UPDATE_FORM_SPEECH_DATA";

export const GET_PATIENT_NOTE_LOADING = "GET_PATIENT_NOTE_LOADING";
export const GET_PATIENT_NOTE_SUCCESS = "GET_PATIENT_NOTE_SUCCESS";
export const GET_PATIENT_NOTE_FAIL = "GET_PATIENT_NOTE_FAIL";
export const UPDATE_PATIENT_NOTE = "UPDATE_PATIENT_NOTE";

export const ADD_BANK_SPECIAL_NOTE_LOADING = "ADD_BANK_SPECIAL_NOTE_LOADING";
export const ADD_BANK_SPECIAL_NOTE_SUCCESS = "ADD_BANK_SPECIAL_NOTE_SUCCESS";
export const ADD_BANK_SPECIAL_NOTE_FAIL = "ADD_BANK_SPECIAL_NOTE_FAIL";

export const GET_SELECTED_IMAGES_SUCCESS = "GET_SELECTED_IMAGES_SUCCESS";
export const GET_SELECTED_DOCUMENTS_SUCCESS = "GET_SELECTED_DOCUMENTS_SUCCESS";

export const UPDATE_VISIT_LOADING = "UPDATE_VISIT_LOADING";
export const UPDATE_VISIT_SUCCESS = "UPDATE_VISIT_SUCCESS";
export const UPDATE_VISIT_FAIL = "UPDATE_VISIT_FAIL";
export const UPDATE_VISIT = "UPDATE_VISIT";
export const ADD_MEDICINE_OPD = "ADD_MEDICINE_OPD";

export const HE_ALERT_TOGGLE = "HE_ALERT_TOGGLE";

export const SHOW_OPD_REPORT = "SHOW_OPD_REPORT";

export const REMOVE_BANK_SPECIAL_NOTE = "REMOVE_BANK_SPECIAL_NOTE";

export const APPOINTMENTS_ALL_MULTI_QUEUE_SUCCESS =
  "APPOINTMENTS_ALL_MULTI_QUEUE_SUCCESS";

export const UPLOAD_VISIT_IMAGE_SUCCESS = "UPLOAD_VISIT_IMAGE_SUCCESS";
export const DELETE_VISIT_IMAGE_SUCCESS = "DELETE_VISIT_IMAGE_SUCCESS";

export const VIEW_VISIT_IMAGE_SUCCESS = "VIEW_VISIT_IMAGE_SUCCESS";
export const UPLOAD_VISIT_PDF_SUCCESS = "UPLOAD_VISIT_PDF_SUCCESS";

export const GET_PATIENT_RISK_LOADING = "GET_PATIENT_RISK_LOADING";
export const GET_PATIENT_RISK_SUCCESS = "GET_PATIENT_RISK_SUCCESS";
export const GET_PATIENT_RISK_FAIL = "GET_PATIENT_RISK_FAIL";

export const SAVE_PATIENT_RISK_SUCCESS = "SAVE_PATIENT_RISK_SUCCESS";

export const UPDATE_RISK_FACTORS = "UPDATE_RISK_FACTORS";

export const CLOSE_VISIT_LOADING = "CLOSE_VISIT_LOADING";
export const CLOSE_VISIT_SUCCESS = "CLOSE_VISIT_SUCCESS";
export const CLOSE_VISIT_FAIL = "CLOSE_VISIT_FAIL";

export const OPEN_VISIT_LOADING = "OPEN_VISIT_LOADING";
export const OPEN_VISIT_SUCCESS = "OPEN_VISIT_SUCCESS";
export const OPEN_VISIT_FAIL = "OPEN_VISIT_FAIL";

export const REPLICATE_VISIT_STATUS = "REPLICATE_VISIT_STATUS";

export const STORE_OPEN_VISIT_dATA = "STORE_OPEN_VISIT_dATA";

export const GET_OPD_FORMS_SUCCESS = "GET_OPD_FORMS_SUCCESS";

export const CERTIFICATE_TOGGLE = "CERTIFICATE_TOGGLE";
export const REMOVE_CERTIFICATE_DATA = "REMOVE_CERTIFICATE_DATA";
export const CREATE_FORM_ARRAY = "CREATE_FORM_ARRAY";

export const GET_VITALS_CHART_SUCCESS = "GET_VITALS_CHART_SUCCESS";

export const DUPLICATE_VISIT_LOADING = "DUPLICATE_VISIT_LOADING";
export const DUPLICATE_VISIT_SUCCESS = "DUPLICATE_VISIT_SUCCESS";
export const DUPLICATE_VISIT_FAILURE = "DUPLICATE_VISIT_FAILURE";

//opd patient invoice

export const GET_OPD_PATIENT_INVOICE_LOADING =
  "GET_OPD_PATIENT_INVOICE_LOADING";
export const GET_OPD_PATIENT_INVOICE_SUCCESS =
  "GET_OPD_PATIENT_INVOICE_SUCCESS";
export const GET_OPD_PATIENT_INVOICE_FAIL = "GET_OPD_PATIENT_INVOICE_FAIL";
export const GET_LIST_VALUE_LOADING = "GET_LIST_VALUE_LOADING";
export const GET_LIST_VALUE_SUCCESS = "GET_LIST_VALUE_SUCCESS";
export const GET_LIST_VALUE_FAIL = "GET_LIST_VALUE_FAIL";

export const GET_ALL_PATIENT_VISITS_LOADING = "GET_ALL_PATIENT_VISITS_LOADING";
export const GET_ALL_PATIENT_VISITS_SUCCESS = "GET_ALL_PATIENT_VISITS_SUCCESS";
export const GET_ALL_PATIENT_VISITS_FAIL = "GET_ALL_PATIENT_VISITS_FAIL";

export const GET_SINGLE_VISIT = "GET_SINGLE_VISIT";
export const GET_SINGLE_VISIT_STATUS = "GET_SINGLE_VISIT_STATUS";
export const ADD_EMP_CARD_POST = "ADD_EMP_CARD_POST";

export const GET_SUGGESTED_MEDICINES_LOADING =
  "GET_SUGGESTED_MEDICINES_LOADING";
export const GET_SUGGESTED_MEDICINES_SUCCESS =
  "GET_SUGGESTED_MEDICINES_SUCCESS";
export const GET_SUGGESTED_MEDICINES_FAIL = "GET_SUGGESTED_MEDICINES_FAIL";

export const GET_OPD_SMS_INVOICE = "GET_OPD_SMS_INVOICE";
export const GET_OPD_SMS_INVOICE_SUCCESS = "GET_OPD_SMS_INVOICE_SUCCESS";
export const GET_OPD_SMS_INVOICE_FAIL = "GET_OPD_SMS_INVOICE_FAIL";

export const REPLICATE_TEMPLATE_LOADING = "REPLICATE_TEMPLATE_LOADING";
export const REPLICATE_TEMPLATE_SUCCESS = "REPLICATE_TEMPLATE_SUCCESS";
export const REPLICATE_TEMPLATE_FAIL = "REPLICATE_TEMPLATE_FAIL";

export const SAVE_TEMPLATE_LOADING = "SAVE_TEMPLATE_LOADING";
export const SAVE_TEMPLATE_SUCCESS = "SAVE_TEMPLATE_SUCCESS";
export const SAVE_TEMPLATE_FAIL = "SAVE_TEMPLATE_FAIL";

export const DELETE_TEMPLATE_LOADING = "DELETE_TEMPLATE_LOADING";
export const DELETE_TEMPLATE_SUCCESS = "DELETE_TEMPLATE_SUCCESS";
export const DELETE_TEMPLATE_FAIL = "DELETE_TEMPLATE_FAIL";

export const VISIT_PRINT_REPORT_LOADING = "VISIT_PRINT_REPORT_LOADING";
export const VISIT_PRINT_REPORT_SUCCESS = "VISIT_PRINT_REPORT_SUCCESS";
export const VISIT_PRINT_REPORT_FAIL = "VISIT_PRINT_REPORT_FAIL";

//OPD UPDATE INVOICE

export const GET_OPD_UPDATE_VISIT_INVOICE = "GET_OPD_UPDATE_VISIT_INVOICE";
export const GET_OPD_UPDATE_VISIT_INVOICE_SUCCESS =
  "GET_OPD_UPDATE_VISIT_INVOICE_SUCCESS";
export const GET_OPD_UPDATE_VISIT_INVOICE_FAIL =
  "GET_OPD_UPDATE_VISIT_INVOICE_FAIL";

//OPD ADD INVOICE

// doctor edit medicine
export const GET_SINGLE_MEDICINE_dETAILS = "GET_SINGLE_MEDICINE_dETAILS";
export const GET_MEDICINE_LIST_LOADING = "GET_MEDICINE_LIST_LOADING";
export const GET_MEDICINE_LIST_SUCCESS = "GET_MEDICINE_LIST_SUCCESS";
export const GET_MEDICINE_LIST_ERROR = "GET_MEDICINE_LIST_ERROR";

export const ADD_MEDICINIE_LIST_LOADING = "ADD_MEDICINIE_LIST_LOADING";
export const ADD_MEDICINIE_LIST_SUCCESS = "ADD_MEDICINIE_LIST_SUCCESS";
export const ADD_MEDICINIE_LIST_FAIL = "ADD_MEDICINIE_LIST_FAIL";

export const UPDATE_MEDICINIE_LIST_LOADING = "UPDATE_MEDICINIE_LIST_LOADING";
export const UPDATE_MEDICINIE_LIST_SUCCESS = "UPDATE_MEDICINIE_LIST_SUCCESS";
export const UPDATE_MEDICINIE_LIST_FAIL = "UPDATE_MEDICINIE_LIST_FAIL";

export const DELETE_MEDICINIE_LIST_LOADING = "DELETE_MEDICINIE_LIST_LOADING";
export const DELETE_MEDICINIE_LIST_SUCCESS = "DELETE_MEDICINIE_LIST_SUCCESS";
export const DELETE_MEDICINIE_LIST_FAIL = "DELETE_MEDICINIE_LIST_FAIL";

//call summary
export const CALL_SUMMARY_LOADING = "CALL_SUMMARY_LOADING";
export const CALL_SUMMARY_SUCCESS = "CALL_SUMMARY_SUCCESS";
export const CALL_SUMMARY_FAIL = "CALL_SUMMARY_FAIL";

//call log
export const CALL_LOG_LOADING = "CALL_LOG_LOADING";
export const CALL_LOG_SUCCESS = "CALL_LOG_SUCCESS";
export const CALL_LOG_FAIL = "CALL_LOG_FAIL";

//call made today
export const CALL_MADE_TODAY_LOADING = "CALL_MADE_TODAY_LOADING";
export const CALL_MADE_TODAY_SUCCESS = "CALL_MADE_TODAY_SUCCESS";
export const CALL_MADE_TODAY_FAIL = "CALL_MADE_TODAY_FAIL";

//call history
export const CALL_HISTORY_LOADING = "CALL_HISTORY_LOADING";
export const CALL_HISTORY_SUCCESS = "CALL_HISTORY_SUCCESS";
export const CALL_HISTORY_FAIL = "CALL_HISTORY_FAIL";

//call delete
export const CALL_DELETE_LOADING = "CALL_DELETE_LOADING";
export const CALL_DELETE_SUCCESS = "CALL_DELETE_SUCCESS";
export const CALL_DELETE_FAIL = "CALL_DELETE_FAIL";

//call plan
export const CALL_PLAN_LOADING = "CALL_PLAN_LOADING";
export const CALL_PLAN_SUCCESS = "CALL_PLAN_SUCCESS";
export const CALL_PLAN_FAIL = "CALL_PLAN_FAIL";

// doctor edit masters
export const GET_MASTER_LIST_LOADING = "GET_MASTER_LIST_LOADING";
export const GET_MASTER_LIST_SUCCESS = "GET_MASTER_LIST_SUCCESS";
export const GET_MASTER_LIST_FAIL = "GET_MASTER_LIST_FAIL";

export const GET_MASTER_LIST_VALUE_LOADING = "GET_MASTER_LIST_VALUE_LOADING";
export const GET_MASTER_LIST_VALUE_SUCCESS = "GET_MASTER_LIST_VALUE_SUCCESS";
export const GET_MASTER_LIST_VALUE_FAIL = "GET_MASTER_LIST_VALUE_FAIL";
export const STORE_MASTER_LIST_VALUE = "STORE_MASTER_LIST_VALUE";

export const SAVE_MASTER_LIST_VALUE_LOADING = "SAVE_MASTER_LIST_VALUE_LOADING";
export const SAVE_MASTER_LIST_VALUE_SUCCESS = "SAVE_MASTER_LIST_VALUE_SUCCESS";
export const SAVE_MASTER_LIST_VALUE_FAIL = "SAVE_MASTER_LIST_VALUE_FAIL";

export const EDIT_MASTER_LIST_VALUE_LOADING = "EDIT_MASTER_LIST_VALUE_LOADING";
export const EDIT_MASTER_LIST_VALUE_SUCCESS = "EDIT_MASTER_LIST_VALUE_SUCCESS";
export const EDIT_MASTER_LIST_VALUE_FAIL = "EDIT_MASTER_LIST_VALUE_FAIL";

export const UPDATE_VACCINE_DATE = "UPDATE_VACCINE_DATE";
export const GET_VACCINE_SAVE = "GET_VACCINE_SAVE";
export const GET_VACCINE_SAVE_SUCCESS = "GET_VACCINE_SAVE_SUCCESS";
export const GET_VACCINE_SAVE_FAIL = "GET_VACCINE_SAVE_FAIL";

export const ADD_SERVICE_DETAILS = "ADD_SERVICE_DETAILS";

// Video Call
export const MAKE_VIDEO_CALL_LOADING = "MAKE_VIDEO_CALL_LOADING";
export const MAKE_VIDEO_CALL_SUCCESS = "MAKE_VIDEO_CALL_SUCCESS";
export const MAKE_VIDEO_CALL_FAIL = "MAKE_VIDEO_CALL_FAIL";
export const RESET_TWILIO_TOKEN = "RESET_TWILIO_TOKEN";

export const SEND_SMS_LOADING = "SEND_SMS_LOADING";
export const SEND_SMS_SUCCESS = "SEND_SMS_SUCCESS";
export const SEND_SMS_FAIL = "SEND_SMS_FAIL";
//opd get audio file

export const GET_AUDIO_FILE = "GET_AUDIO_FILE";
export const GET_AUDIO_SUCCESS = "GET_AUDIO_SUCCESS";
export const GET_AUDIO_FAIL = "GET_AUDIO_FAIL";

export const GET_VISIT_AUDIO_FILE = "GET_VISIT_AUDIO_FILE";
export const RESET_VISIT_AUDIO_FILE = "RESET_VISIT_AUDIO_FILE";

export const UPDATE_LOGIN_STATUS = "UPDATE_LOGIN_STATUS";
export const UPDATE_ADMIN_STATUS = "UPDATE_ADMIN_STATUS";
