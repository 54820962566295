import { combineReducers } from "redux";
import { loginReducer } from './reducers/login'
import { profileReducer } from "./reducers/profile";
import { adminLoginReducer } from "./reducers/adminlogin";
import { getAppointmentsReducer } from "./reducers/appointments/appointments";
import { invoiceSummaryReducer } from "./reducers/Diagnostics/diagnostics"
import { patientListReducer } from "./reducers/opd/patientlist";
import { userSignUpReducer } from './reducers/admin/userSignup';
import { medicineMasterReducer } from "./reducers/medicinemaster/medicinemaster";
import { medicineActivateReducer } from "./reducers/medicinemaster/medicineactivate";
import { medicineDeactivateReducer } from "./reducers/medicinemaster/medicinedeactive";
import {medicineAddReducer} from "./reducers/medicinemaster/medicineadd"
import { doctorStatisticsReducer } from "./reducers/doctorstatistics/doctorstatistics";
import {patientDndReducer} from "./reducers/patientDnd/patientDnd"
import {patientSubmitReducer} from "./reducers/patientDnd/patientSubmit"
// import { getAppointmentsReducer } from "./reducers/appointments";
// import {opdRecordReducer} from './reducers/opd/opdrecord'
import { medicineListReducer } from "./reducers/doctorEditMedicine/medicineList";
import { masterListReducer } from "./reducers/doctorEditMaster/masterList";
import { patientAudioReducer } from "./reducers/opd/patientaudio";
import { patientVaccineReducer } from "./reducers/opd/patientvaccine";
import { patientReportReducer } from "./reducers/opd/patientreport";
import { searchPatientReducer } from "./reducers/appointments/searchPatient";
import { searchAdvPatientReducer } from "./reducers/appointments/patientAdvSearch";
import { createPatientReducer } from "./reducers/appointments/createPatient";
import { getPatientAppointmentReducer } from "./reducers/appointments/getPatient";
import { savePatientReducer } from "./reducers/appointments/savePatient";
import { deleteAppointmentReducer } from "./reducers/appointments/deleteAppointment";
import { updateAppointmentReducer } from "./reducers/appointments/updateAppointment";
import { accessTokenReducer } from "./reducers/appointments/accessToken";
import { updateAppointmentViewReducer } from "./reducers/appointments/updateAppointmentView";
import { setPartnerReducer } from "./reducers/appointments/setPartner";
import { printReportAppointmentReducer } from "./reducers/appointments/printReportAppointment";
import { printPatientProfileReducer } from "./reducers/appointments/printPatientProfile";
import { editInvoiceReducer } from "./reducers/appointments/editInvoice";
import { printInvoiceReducer } from "./reducers/appointments/printInvoice";
import { addAppointmentReducer } from "./reducers/appointments/addAppointment";
import {doctorSignUpReducer } from "./reducers/admin/DoctorSignUp/doctorSignUp"
import { doctorListReducer } from "./reducers/admin/DoctorSignUp/doctorList"
import { alternateNumReducer } from "./reducers/admin/alternateNum"
import { updateMedicineListReducer } from "./reducers/doctorEditMedicine/updateMedicineList";
import { deleteMedicineListReducer } from "./reducers/doctorEditMedicine/deleteMedicineList";
import { getPatientReducer } from "./reducers/Diagnostics/getPatient"
import { addMedicineListReducer } from "./reducers/doctorEditMedicine/addMedicineList";
import { masterListValueReducer } from "./reducers/doctorEditMaster/masterListValue";
import { saveMasterListValueReducer } from "./reducers/doctorEditMaster/saveMasterList";
import { detailSummaryReducer} from "./reducers/Diagnostics/invoiceSummary"
import { VaccineSaveData } from "./reducers/opd/VaccineSave";
import { OpdSmsInvoiceReducer } from "./reducers/opd/OpdSmsInvoice";
import { subDomainReducer } from "./reducers/admin/subDomain"
import { UpdateVisitOpdInvoiceReducer } from "./reducers/opd/UpdateVisitOpdInvoice";
import { patientOpdInvoiceReducer } from "./reducers/opd/patientopdinvoice";
import { saveDiagnosticReducer } from "./reducers/Diagnostics/saveDiagnostic"
import { sendPatientToDoctorReducer } from "./reducers/opd/sendPatientDoctor";
import {callMadeTodayReducer} from "./reducers/calls/callSummary";
import {callLogReducer} from "./reducers/calls/callSummary";
import {callDeleteReducer} from "./reducers/calls/callSummary";
import {callSummaryReducer} from "./reducers/calls/callSummary";
import {callHistoryReducer} from "./reducers/calls/callSummary";
import {callPlanReducer} from "./reducers/calls/callSummary";
import  { patientHistoryReducer } from "./reducers/opd/patienthistory"
import { videoCallReducer } from "./reducers/video.js/videoCall";
import { analyticsReducer } from "./reducers/analytics/analytics";




const rootReducer=combineReducers(
    {
     login:loginReducer,
     profile:profileReducer,
     adminLogin:adminLoginReducer, 
     getAppointment:getAppointmentsReducer,
    //  analytics
     analytics:analyticsReducer,

    
    // diagnostic 
     diagnosticsInvoiceSummary:invoiceSummaryReducer,
     getPatient:getPatientReducer,
     invoiceSummary:detailSummaryReducer,
     saveDiagnostic:saveDiagnosticReducer,

     //
     userSignup:userSignUpReducer,
     doctorSignup:doctorSignUpReducer,
     doctorListDetails:doctorListReducer,
     alternateNumb:alternateNumReducer,
     patientlist:patientListReducer,
     medicineMaster:medicineMasterReducer,
     medicineActivate:medicineActivateReducer,
     medicineDeativate:medicineDeactivateReducer,
     medicineAdd:medicineAddReducer,
     doctorStatistics:doctorStatisticsReducer,
     patientDnd:patientDndReducer,
     subDomian:subDomainReducer,
     //calls
     callLog:callLogReducer,
     callMadeToday:callMadeTodayReducer,
     callSummary:callSummaryReducer,
     callPlan:callPlanReducer,
     callHistory:callHistoryReducer,
     callDelete:callDeleteReducer,

     

     patientSubmit:patientSubmitReducer,
     opdrecord:combineReducers({
        VaccineSave:VaccineSaveData,
        patientvaccine:patientVaccineReducer,
        patientlist:patientListReducer,
        patientaudio:patientAudioReducer,
        patientHistory:patientHistoryReducer,
        patientreport:patientReportReducer,
        OpdSmsInvoice:OpdSmsInvoiceReducer,
        UpdateVisitOpdInvoice:UpdateVisitOpdInvoiceReducer,
        patientopdinvoice:patientOpdInvoiceReducer,
        sendPatientDoctor:sendPatientToDoctorReducer,
        
    }),
    appointments:combineReducers({
        getAppointment:getAppointmentsReducer,
        searchPatient:searchPatientReducer,
        searchAdvPatient:searchAdvPatientReducer,
        createPatient:createPatientReducer,
        addAppointment:addAppointmentReducer,
        getPatient:getPatientAppointmentReducer,
        savePatient:savePatientReducer,
        deleteAppointment:deleteAppointmentReducer,
        updateAppointment:updateAppointmentReducer,
        accessToken:accessTokenReducer,
        updateAppointmentsView:updateAppointmentViewReducer,
        setPartner:setPartnerReducer,
        printReportAppointment:printReportAppointmentReducer,
        printPatientProfile:printPatientProfileReducer,
        editInvoice:editInvoiceReducer,
        printInvoice:printInvoiceReducer

    }),
    editMedicine: combineReducers({
        editMedicineInfo: medicineListReducer,
        updateMedicineInfo:updateMedicineListReducer,
        deleteMedicineInfo:deleteMedicineListReducer,
        addMedicineInfo:addMedicineListReducer
    }),
    editMaster: combineReducers({
        editMasterInfo: masterListReducer,
        masterListValueInfo : masterListValueReducer,
        saveMasterListInfo:saveMasterListValueReducer
    }),
    videoCalls:videoCallReducer
    })


export default rootReducer
